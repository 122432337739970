<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >INVENTARIO</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">HISTORIAL DE TRASPASOS</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <span style="border-bottom: 1px solid #005f91; font-size: 1.5rem">
        <strong>HISTORIAL DE TRASPASOS</strong>
      </span>
    </div>
    <div class="col-12 p-fondo">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-3">
          <span><strong>C&Oacute;D TRASPASO: </strong></span>
          <InputText
            v-model="cod_traspaso"
            placeholder="Cod. Mov"
            :class="'text-right'"
            @keyup.enter="BuscarFiltro"
          />
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>FECHA TRASPASO DESDE: </strong></span>
          <Calendar
            v-model="fecha_traspaso_desde"
            dateFormat="dd/mm/yy"
            :showIcon="true"
          />
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>FECHA TRASPASO HASTA: </strong></span>
          <Calendar
            v-model="fecha_traspaso_hasta"
            dateFormat="dd/mm/yy"
            :showIcon="true"
          />
        </div>
        <div class="field col-12 md:col-3">
          <span><strong>ACCIONES: </strong></span>
          <Button
            label="APLICAR FILTROS"
            icon="pi pi-search"
            class="p-button-info"
            v-tooltip.top="'Buscar'"
            :loading="enviando"
            :disabled="enviando"
            @click="BuscarFiltro"
          />
        </div>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dt_traspasos"
        key="id"
        :value="traspasos"
        responsiveLayout="scroll"
        :loading="enviando"
        class="p-datatable-sm"
        :filters="buscar"
        responsive="true"
        stripedRows
        showGridlines
        :rows="50"
        :paginator="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[50, 100, 500, 1000, 5000, 10000]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Reportes de Movimientos"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar Movimientos..."
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <!-- v-if="'Inventario TraspasosPDF' in auth.user.permissions" -->
              <Button
                label="DESCARGAR PDF"
                icon="pi pi-file-pdf"
                class="p-button-danger"
                v-tooltip.top="'Imprimir Reporte PDF'"
                :loading="generandoPDF"
                :disabled="generandoPDF"
                @click="generarPDF"
              />
            </span>
          </div>
        </template>
        <template #loading>
          <div class="flex align-items-center justify-content-center">
            <ProgressSpinner />
          </div>
        </template>
        <template #empty>
          <span class="p-invalid">
            Debe aplicar filtros para ver resultados en la tabla!</span
          >
        </template>
        <Column field="codigo" header="CÓD" :sortable="true"> </Column>
        <Column field="sucursal_principal" header="SUCURSAL PRINCIPAL"></Column>
        <Column field="preparado_por" header="PREPARADO POR"> </Column>
        <Column field="nombre_producto" header="PRODUCTO/DESCRIPCIÓN"> </Column>
        <Column field="cantidad" header="CANTIDAD" style="text-align: center">
          <template #body="slotProps">
            <span>{{ sinDecimal(slotProps.data.cantidad) }}</span>
          </template>
        </Column>
        <Column field="recido_por" header="RECIBIDO POR"> </Column>
        <Column field="sucursal_destino" header="SUCURSAL DESTINO"> </Column>
        <Column field="observaciones" header="OBSERVACION"></Column>
      </DataTable>
    </div>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api";
import { useAuth } from "@/stores";
import MovimientoInventarioService from "@/service/MovimientoInventarioService";

export default {
  data() {
    return {
      enviando: false,
      fecha_traspaso_desde: null,
      fecha_traspaso_hasta: null,
      cod_traspaso: null,
      generandoPDF: false,
      buscar: {
        global: {
          value: "",
          matchMode: FilterMatchMode.CONTAINS,
        },
      },
      traspasos: [],
    };
  },
  auth: null,
  movimientoInventarioService: null,
  created() {
    this.auth = useAuth();
    this.movimientoInventarioService = new MovimientoInventarioService();
  },
  methods: {
    sinDecimal(numero) {
      return parseInt(numero);
    },
    imprimirTraspaso(id) {
      this.movimientoInventarioService
        .imprimirTraspaso(id)
        .then(() => {})
        .catch((error) => console.log(error));
    },
    generarPDF() {
      if (this.traspasos.length > 0) {
        this.generandoPDF = true;
        let datos = {
          cod_traspaso: this.cod_traspaso,
          fecha_traspaso_desde: this.fecha_traspaso_desde,
          fecha_traspaso_hasta: this.fecha_traspaso_hasta,
        };
        this.movimientoInventarioService.imprimirPDF(datos).then(() => {
          this.generandoPDF = false;
          this.$toast.add({
            severity: "success",
            summary: "Éxito",
            detail: "Se generó el PDF correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Advertencia",
          detail: "No hay datos para generar el reporte",
          life: 3000,
        });
      }
    },
    BuscarFiltro() {
      this.traspasos = [];
      this.enviando = true;
      this.cargarTraspasos();
    },
    cargarTraspasos() {
      let datos = {
        cod_traspaso: this.cod_traspaso,
        fecha_traspaso_desde: this.fecha_traspaso_desde,
        fecha_traspaso_hasta: this.fecha_traspaso_hasta,
      };
      this.movimientoInventarioService
        .filtrarTraspasos(datos)
        .then((response) => {
          this.traspasos = response.traspasos;
          this.enviando = false;
        });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}

.p-fondo {
  background-color: #7bffae;
  border-radius: 5px;
  border: 1px solid #002236;
  padding: 5px;
}
</style>
